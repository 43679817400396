.buySellSection {
    background: var(--bodyColor);
}

.changeBuySell {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.changeBuySell p {
    margin: 0;
    color: var(--textColor);
    font-size: 12px;
}

.changeBuySell button {
    background: var(--colorPrimary);
    color: black;
    font-weight: 600;
    font-size: 14px;
    border: none;
    border-radius: 20px;
    padding: 3px 16px;
}

.swapSaction {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
}

.swapBg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    filter: blur(10px) brightness(0.7);
    display: none
}

.swapDiv {
    position: relative;
    z-index: 1;
    background: rgba(65, 65, 65, 0.1);
    box-shadow: 0px 0px 1700px 0px var(--colorPrimary);
}

.swapDiv input {
    border: 1px solid rgba(128, 128, 128, 0.39);
}

.swapLogo {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.swapLogo img {
    width: 150px;
}