@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600&family=Lato:ital,wght@0,300;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Nunito:ital,wght@0,300;0,500;0,600;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Open+Sans:ital,wght@0,600;0,700;1,400;1,500;1,700&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,900;1,700&display=swap');


.loginContent {
    border-radius: 10px;
    padding: 20px;
    max-width: 420px;
    position: relative;
    margin: auto;
    width: 100%;
    background: var(--bodyColor);
    -webkit-user-select: none;
    user-select: none;
}

#logincontainer {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgb(0, 0, 0) 0%, #00ffa05c  10%, rgba(0, 0, 0, 1) 100%);
    padding-top: 100px;
    padding-bottom: 100px;

}

a.loginClick {
    color: white;
    font-weight: 600;
    text-decoration: none;
}

a.loginClick i {
    font-size: 10px;

}

.loginLogo {
    display: block;
    text-align: center;
}

.loginLogo img {
    width: 100%;
    max-width: 140px;
    margin: 20px auto;
    position: relative;
    z-index: 99;
}

.loginContent_inner {
    text-align: center;
    background: var(--bodyColor);
    padding: 20px;
    border-radius: 10px;
}

.loginContent_inner h4 {
    font-size: 24px;
    margin: 0px;
    font-weight: 500;
    color: var(--textHeading);
}

.loginContent_inner p {
    margin: 5px 0px 10px 0px;
    text-transform: capitalize;
    font-size: 16px;
    color: white;
}

.loginInput_inner {
    position: relative;
}

.loginInput_inner input {
    position: relative;
    width: 100%;
    padding: 8px 20px;
    padding-right: 40px;
    border-radius: 25px;
    border: 1px solid rgba(128, 128, 128, 0.192);
    outline: none;
    margin-bottom: 10px;
    background: var(--lightColor) !important;
    color: var(--textHeading);
    font-size: 14px;
}

.form-control:focus {
    border: 1px solid var(--textColor) !important;
}

.loginInput_inner i {
    position: absolute;
    right: 15px;
    top: 11px;
    color: var(--colorPrimary);
    display: flex;
}

.loginForgot_link {
    text-align: end;
    margin-bottom: 20px;
}

.loginForgot_link a {
    color: #C6C6C6;
    text-decoration: none;
    text-transform: capitalize;
}

button.LoginBtn {
    padding: 10px 40px;
    border-radius: 40px;
    border: none;
    background: var(--colorPrimary);
    font-size: 16px;
}

p.sign_log {
    font-size: 12px;
    color: #9f9f9f;
}

p.sign_log a {
    color: var(--colorPrimary);
    text-decoration: none;
}


.loginSilde_img img {
    filter: hue-rotate(109deg);
    width: 58%;
    position: absolute;
    top: -20px;
    right: -20px;
    border-top-right-radius: 10px;
}

.loginSilde_img {
    position: relative;
}

.loginFooter_btn a {
    color: black;
    display: inline-block;
    width: auto;
    padding: 10px 40px;
    text-decoration: none;
    box-shadow: none;
}

#trustedLogo {
    width: 80px;
    filter: brightness(1.5);
    position: absolute;
    top: 2px;
    left: -3px;
    transform: rotate(340deg);
}

#handImage {
    width: 60%;
    filter: drop-shadow(0px 0px 0px var(--colorPrimary));
}

#trustShlogan {
    color: var(--colorPrimary);
    font-weight: bold;
    margin-top: 15px;
    text-transform: capitalize;
    font-size: 25px;

}